/*@import url(//db.onlinewebfonts.com/c/685846eba2b5b1bc0a6548d693ab8123?family=Basis+Grotesque+Pro+Mono);*/


@font-face {
    font-family: 'Basis Grotesque Pro Regular';
    src: url('static/fonts/basis_grotesque_pro_regular-web.woff2') format('woff2'),
       url('static/fonts/basis_grotesque_pro_regular-web.woff') format('woff'),
       url('static/fonts/basis_grotesque_pro_regular-web.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Basis Grotesque Pro Mono';
    src: url('static/fonts/basis_grotesque_pro_mono-web.woff2') format('woff2'),
       url('static/fonts/basis_grotesque_pro_mono-web.woff') format('woff'),
       url('static/fonts/basis_grotesque_pro_mono-web.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Basis Grotesque Pro Bold';
  src: url('static/fonts/BasisGrotesqueMonoPro-Bold.woff2') format('woff2'),
     url('static/fonts/BasisGrotesqueMonoPro-Bold.woff') format('woff'),
     url('static/fonts/BasisGrotesqueMonoPro-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


html{font-family:Basis Grotesque Pro Regular,Arial,sans-serif;font-weight:400;color:#292829;font-size:12px; color: black;}
h1,h2,h3,h4,h5,h6{font-weight:400;font-size:inherit}
ol,ul{margin:8px 0;padding-left:20px}
.navigation {display: flex; flex-flow: column wrap; justify-content:flex-end;list-style:none;margin:0;}
.navigation li{margin-top: 15px;}
.redlighted{color: #ff251d;text-shadow: 0 0 0.3rem #ff251d;}
.redlighted:hover{color: #000;text-shadow: 0;}
p{line-height:18px}
a{color:#000}
::-moz-selection{background:#000;color:#fff;text-decoration:none;text-shadow:none}
::selection{background:#000;color:#fff;text-decoration:none;text-shadow:none}
*{background:transparent no-repeat;border:0;margin:0;outline:0;padding:0;word-wrap:break-word}
html{background-color:#f9f6ef}
strong{font-family:Basis Grotesque Pro Bold;font-weight:700}
.js .ff{visibility:hidden}
.js.wf-active .ff,.js.wf-inactive .ff{visibility:visible}
body{margin:0;-webkit-tap-highlight-color:transparent}
img{-ms-interpolation-mode:bicubic;display:block}
table{border-spacing:0;width:100%}
#outdated{display:none;position:fixed;top:0;left:0;width:100%;height:170px;text-align:center;text-transform:uppercase;z-index:1500;background-color:#f25648;color:#fff}
* html #outdated{position:absolute}
#outdated h6{font-size:25px;line-height:25px;margin:30px 0 10px}
#outdated p{font-size:12px;line-height:12px;margin:0}
#outdated #btnUpdateBrowser{display:block;position:relative;padding:10px 20px;margin:30px auto 0;width:230px;color:#fff;text-decoration:none;border:2px solid #fff;cursor:pointer}
#outdated #btnUpdateBrowser:hover{color:#f25648;background-color:#fff}
#outdated .last{position:absolute;top:10px;right:25px;width:20px;height:20px}
#outdated #btnCloseUpdateBrowser{display:block;position:relative;width:100%;height:100%;text-decoration:none;color:#fff;font-size:36px;line-height:36px}
.ie8 #outdated,.ie9 #outdated{display:block}
.grid__container{display:none}
.team-image-map {position: absolute;}
.timeline-container {width: 90%; margin-left: 10%; margin-top: 5%; height: 70%;}
.llAttw.right {margin-left: -10% !important}
.grid{background-image:url(./static/grid.svg);background-repeat:repeat;position:absolute;top:0}
.grid.grid__top{min-height:866px;left:0;width:100%}
.grid.grid__top:before{height:108px;top:0;width:162px}
.grid.grid__top:after,.grid.grid__top:before{background:#f9f6ef;content:"";left:0;position:absolute;z-index:1}
.grid.grid__top:after{height:434px;width:272px;bottom:0;margin-left:-2px}
.grid.grid__right{min-width:704px;left:auto;right:0}
.grid{background-size:54px 54px}
.arrows__pattern{background-image:url(./static/arrows_pattern.png);background-repeat:no-repeat}
.circles__pattern{background-image:url(./static/circles_pattern.png);background-repeat:no-repeat}
.dots__pattern{background-image:url(./static/dots_pattern.png);background-repeat:no-repeat}
.lines__pattern{background-image:url(./static/lines_pattern.png);background-repeat:no-repeat}
.stream__pattern{background-image:url(./static/stream_pattern.png);background-repeat:no-repeat}
.waves__pattern{background-image:url(./static/waves_pattern.png);background-repeat:no-repeat}
.windows__pattern{background-image:url(./static/windows_pattern.png)}
.pattern,.windows__pattern{background-repeat:no-repeat}
    .pattern{background-image:url(./static/waves_pattern.png)}
    .pattern__arrows .pattern{background-image:url(./static/arrows_pattern.png);background-repeat:no-repeat}
    .pattern__circles .pattern{background-image:url(./static/circles_pattern.png);background-repeat:no-repeat}
    .pattern__dots .pattern{background-image:url(./static/dots_pattern.png);background-repeat:no-repeat}
    .pattern__lines .pattern{background-image:url(./static/lines_pattern.png);background-repeat:no-repeat}
    .pattern__stream .pattern{background-image:url(./static/stream_pattern.png);background-repeat:no-repeat}
    .pattern__waves .pattern{background-image:url(./static/waves_pattern.png);background-repeat:no-repeat}
    .pattern__windows .pattern{background-image:url(./static/windows_pattern.png);background-repeat:no-repeat}
    .page__header-pattern{background-size:750px 500px;position:absolute}
    .page__blog .page__header-pattern,.page__home .page__header-pattern,.page__info .page__header-pattern,.page__program .page__header-pattern,.page__tickets .page__header-pattern{height:52px;box-sizing:border-box;width:52px;right:2px;top:2px}
    .page__header-pattern-second{background-size:750px 500px;position:absolute}
    .header__container{height:108px;margin:54px auto 0;position:relative;width:272px}
    .header__container:after,.header__container:before{content:"";display:table}
    .header__container:after{clear:both}
    .header__logo{background-image:url(./static/logo.svg);background-repeat:no-repeat;width:110px;height:110px;float:left;position:relative;z-index:2}
    .header__language{position:absolute;right:0}
    .header__language .footer__icon-facebook{display:none;font-size:14px;line-height:16px}
    .header__language .footer__icon-instagram,.header__language .footer__icon-snapchat{display:none;font-size:14px;line-height:16px;position:relative}
    .header__language .footer__icon-snapchat:before{content:"|";position:absolute;right:-15px}
    .header__language--item{font-family:Basis Grotesque Pro Mono,Arial,sans-serif;font-weight:400;font-size:14px;line-height:16px;text-decoration:none; cursor: pointer; color: black;}
    .header__language--item.is-active,.header__language--item:hover{text-decoration:underline}
    .header__language-link{text-decoration:none}
    .header__language-link:hover{text-decoration:underline}
    .footer__container{margin:120px auto;padding:26px 0 0;width:268px}
    .footer__container:after,.footer__container:before{content:"";display:table}
    .footer__container:after{clear:both}
    .footer__row{font-family:Basis Grotesque Pro Mono,Arial,sans-serif;font-weight:400;font-size:11px;line-height:24px;text-align:center}
    .footer__row+.footer__row{margin-top:30px}
    .footer__item{display:inline}
    .footer__link{transition:all .2s ease-out 0s;text-decoration:none}
    .footer__link:hover{text-decoration:underline}
    .footer__link-bravoure{text-decoration:none}
    .footer__link-bravoure:hover{text-decoration:underline}
    .footer__icon-facebook{transition:all .2s ease-out 0s;display:inline-block;height:23px;margin-right:25px;text-decoration:none}
    .footer__icon-facebook:hover{text-decoration:underline}
    .footer__icon-instagram{transition:all .2s ease-out 0s;display:inline-block;height:19px;margin-right:25px;text-decoration:none}
    .footer__icon-instagram:hover{text-decoration:underline}
    .footer__icon-snapchat{transition:all .2s ease-out 0s;display:inline-block;height:19px;text-decoration:none}
    .footer__icon-snapchat:hover{text-decoration:underline}
    .navigation__trigger{clear:right;cursor:pointer;float:right;font-size:14px;line-height:16px;margin-top:34px;padding-right:14px;position:relative}
    .navigation__trigger.is-active:after{display:none}
    .navigation__trigger:before{background-color:#292829;content:"";height:1px;position:absolute;right:0;top:7px;width:9px}
    .navigation__trigger:after{background-color:#292829;content:"";height:9px;position:absolute;right:4px;top:3px;width:1px}
        .navigation__container{font-family:Basis Grotesque Pro Mono,Arial,sans-serif;font-weight:400;display:none;position:absolute;top:162px;width:100%;z-index:100}
        .teams__navigation{font-family:Basis Grotesque Pro Mono,Arial,sans-serif;font-weight:400;display:none;position:absolute;top:108px;width:100%;z-index:100;left: 0;}
        .navigation__item{background-color:#f9f6ef;border-left:2px solid #292829;border-right:2px solid #292829;border-top:2px solid #292829;box-sizing:border-box;display:block;font-size:14px;line-height:16px;padding:18px;text-decoration:none;width:100%}
        .navigation__item:last-child{border-bottom:2px solid #292829}
        .navigation__item.is-active,.navigation__item:hover{text-decoration:underline}
        .form__input--control .form__input--container{float:left;width:100%}
        .form__input--control .form__input--container.medium{margin-right:10px;width:146px}
        .form__input--control .form__input--container.small{width:104px}
        .form__input--container{border-radius:2px;background-color:#f9f6ef;float:left;position:relative;width:100%}
        .form__input--container.checkbox{background:0 0}
        .form__input--container.active .form__label{font-size:10px;line-height:14px;top:5px}
        .form__input--container.first{margin-top:16px}
        .form__input--container+.form__input--container .form__input{border-top:0}
        .form__input--container input[type=checkbox]{opacity:0;left:0;position:absolute;top:0}
        .form__input--container input[type=checkbox]:checked:focus+label:after,.form__input--container input[type=checkbox]:focus:after{background-color:#000}
        /*.form__input--container input[type=checkbox]:checked+label:before{background-image:url(./static/check_icon.png);background-repeat:no-repeat;content:"";height:13px;left:4px;position:absolute;top:4px;width:15px;z-index:1}*/
        .form__input--container input[type=checkbox]+label{box-sizing:border-box;color:#fff;float:left;font-size:12px;line-height:12px;min-height:22px;padding-left:40px;padding-top:2px;position:relative;width:100%}
        .webkit .form__input--container input[type=checkbox]+label{line-height:18px}
        .form__input--container input[type=checkbox]+label:after{transition:all .2s ease-out 0s;border-radius:2px;background-color:#fff;border:2px solid #fff;content:"";height:18px;left:0;position:absolute;top:0;width:18px}
        .form__input--container.error.checkbox{background:0 0}
        .form__input--container.error .form__label,.form__input--container.error .form__label a,.form__input--container.error input[type=checkbox]+label,.form__input--container.error input[type=checkbox]+label a,.form__input--container.error label,.form__input--container.error label a{color:#b64b43}
        .form__label{transition:all .2s ease-out 0s;color:#000;cursor:text;font-size:12px;left:18px;line-height:16px;position:absolute;right:0;text-transform:uppercase;top:18px;z-index:1}
        .form__label.active{font-size:10px;line-height:14px;top:5px}
        .form__label--checkbox{cursor:pointer}
        .form__label--checkbox a{color:#000}
        textarea.form__input{height:160px;line-height:18px;padding:18px; resize: none;}
        .form__input{font-family:Basis Grotesque Pro Regular,Arial,sans-serif;font-weight:400;-webkit-appearance:none;border:2px solid #292829;border-radius:0;font-size:12px;height:56px;line-height:54px;padding:0 18px;z-index:2}
        .form__input,.form__select{box-sizing:border-box;color:#000;position:relative;width:100%}
        .form__select{border-radius:0;-webkit-appearance:inherit;font-size:14px;height:50px;line-height:52px;outline:0;padding:0 0 0 15px;z-index:4}
        .form__select.form__simple-select{background:#fff;border:1px solid #000;border-radius:2px;color:#000;font-size:12px;height:30px;line-height:30px;margin-top:-15px;padding:0 0 0 15px;position:absolute;top:50%}
        select:-moz-focusring{color:transparent;text-shadow:0 0 0 #000}
        .form__input--radio-container{box-sizing:border-box;float:left;padding:16px 0 16px 3px;width:100%}
        .form__input--radio-container:after,.form__input--radio-container:before{content:"";display:table}
        .form__input--radio-container:after{clear:both}
        .form__input--radio-container.error .form__label--radio,.form__input--radio-container.error .form__label--radio-container{color:#b64b43}
        .form__input--radio-container.error .form__label--radio:before{background-color:#ffb2bb;border:2px solid #ffb2bb}
        .form__input--radio{position:relative}
        .form__input--radio.focus+.form__label--radio{background:#000}
        .form__input--radio:after{background-position:50%;background-size:cover;background-position:top;content:"";height:18px;left:0;position:absolute;top:0;width:18px}
        .form__input--radio:checked:after{background-position:bottom}
        .form__submit{border-radius:0;box-sizing:border-box;font-family:Basis Grotesque Pro Regular,Arial,sans-serif;font-weight:400;transition:all .2s ease-out 0s;-webkit-appearance:none;background-color:#f9f6ef;border-bottom:2px solid #292829;border-left:2px solid #292829;border-right:2px solid #292829;color:#000;cursor:pointer;display:table-cell;font-size:16px;height:56px;line-height:50px;padding:0 12px;text-align:center;text-decoration:underline;text-transform:uppercase}
        .form__submit:hover{color:#8f8f8f}
        .form__label--radio{color:#000;cursor:pointer;font-size:13px;padding:0 15px 0 5px;position:relative;z-index:11}
        .form__label--radio:before{transition:all .2s ease-out 0s;background-color:#fff;border:2px solid #fff;border-radius:20px;content:"";cursor:pointer;display:inline-block;height:17px;left:-24px;margin-right:10px;position:absolute;width:17px}
        .form__label--radio-container{color:#000;font-size:13px;line-height:16px;margin-right:40px}
        input[type=radio]{cursor:pointer;height:17px;width:17px;z-index:10}
        input[type=radio]:focus+.form__label--radio:before{transition:all .2s ease-out 0s;background-color:#000;border:2px solid #fff}
        input[type=radio]:checked{background:#000}
        input[type=radio]:checked+.form__label--radio:before{transition:all .2s ease-out 0s;background-color:#000;border:2px solid #fff}
        .form__error-container{float:left;margin-bottom:20px;overflow:hidden;position:relative;width:100%}
        .form__error-container .form__error-message{box-sizing:border-box;border-spacing:0;display:table;padding:0;vertical-align:middle;width:100%}
        .form__error-container .form__error-content{color:#b64b43;display:table-cell;font-size:13px;line-height:normal;vertical-align:middle}
        .form__success-container{float:left;margin-top:20px;overflow:hidden;position:relative;width:100%}
        .form__success-container .form__success-message{box-sizing:border-box;border-spacing:0;display:table;padding:0;vertical-align:middle;width:100%}
        .form__success-container .form__success-content{font-family:Basis Grotesque Pro Mono,Arial,sans-serif;font-weight:400;color:#000;display:table-cell;font-size:12px;line-height:normal;vertical-align:middle}
        .form__input--success-container{display:none;float:left;margin-bottom:10px;overflow:hidden;position:relative;width:100%}
        .form__input--birthday-container .form__select{padding:0;text-align:center}
        .form__input--birthday-container.selected{display:table;float:left;margin:12px 0 0;width:100%}
        .form__input--birthday-container.selected .form__label--desktop{color:#000;display:table-cell;font-size:13px;line-height:17px;position:relative;vertical-align:middle;width:75px}
        .form__input--birthday-container.selected .form__input--birthday-container{border-collapse:separate;border-spacing:12px 0;display:table;margin-left:-12px;width:calc(100% + 23px)}
        .form__input--birthday-container.selected .form__input--birthday-container .form__input--container{display:table-cell;float:none;vertical-align:top;width:33%}
        .form__input--birthday-container.selected .form__input--birthday-container .form__label{left:8px;position:absolute;z-index:2}
        .form__input--birthday-container.error .form__label,.form__input--birthday-container.error .form__label--desktop,.form__input--birthday-container.error .form__select{color:#b64b43}
        .form__select--container{border-radius:3px;background:#fff}
        .form__select--container.focus{background:#000!important}
        .firefox .form__input--birthday-container .form__select{padding-left:5px}
        .firefox .form__select{padding:0 14px;text-align:left}
        .firefox .form__select.form__simple-select{padding:0 0 0 14px}
        .ie9 .form__select{margin-top:0;padding:0 14px}
        .ie9 .form__select.form__simple-select{padding:0 0 0 14px}
        .webkit .form__input--birthday-container .form__input--container .form__select{padding:0 8px}
        .webkit .form__select--container .form__select{padding:0 18px}
        .webkit .form__select--container .form__select:focus+.form__label.active:before{margin-top:4px}
        /*.webkit .form__select--container .form__select+.form__label:before{background-position:50%;background-size:cover;background-image:url(./static/select_icon.png);background-repeat:no-repeat;transition:all .2s ease-out 0s;content:"";height:15px;margin-top:-7px;position:absolute;right:12px;top:50%;width:7px;z-index:2}*/
        .webkit .form__select--container .form__select+.form__label.active:before{margin-top:4px}
        .ie8 input[type=radio]{visibility:visible}
        .lazyload.done{opacity:1}

        .form__input {
            margin: 6px 0 ;
        }

        .form__input.selected {
            background: black;
            color: white;
        }



       /* .background__container-animation{opacity:.5}*/
        .lazyload,.lazyloading{transition:all .2s ease-out 0s;opacity:0}
        .clickable{cursor:pointer}
        .background__container-animation{transition:all .4s ease-out 0s;bottom:0;left:0;position:absolute;right:0;top:0;z-index:1;background-position:50%;background-size:cover;overflow:hidden; opacity: 1}
        .background__container-animation-art{transition:all 0.8s ease-out 0s;bottom:0;left:0;position:absolute;right:0;top:0;z-index:1;background-size:contain;overflow:hidden; opacity: 1; background-position: center;}
        .art__image-go-left {
          position: absolute;
          bottom: -26px;
          left: 0;
          cursor: pointer;
        }
        .art__image-go-right {
          position: absolute;
          bottom: -26px;
          right: 0;
          cursor: pointer;
        }
        @media (min-width:737px) {
          /* .background__container-animation-art{background-color: white;} */
          .art__image-go-left {left: 10px}
          .art__image-go-right {right: 12px;}
        }
        body{overflow-x:hidden}


        .is-open {
            display: block !important;
            transition: all ease-out 1s;
        }




        .block__section{box-sizing:border-box;display:block;margin:auto;padding:0 10px 50px;position:relative;width:100%;z-index:2}
        .block__section:after,.block__section:before{content:"";display:table}
        .block__section:after{clear:both}
        .block__title{color:#fff;display:inline-block;font-size:12px;letter-spacing:1.97px;line-height:14px;margin:50px 0;text-transform:uppercase;width:100%}
        .block__title.block__title-top__space{margin-top:100px}
        .social-icons__component{display:none}
        .social-icons__nav{box-sizing:border-box;transition:all .3s cubic-bezier(.68,-.55,.265,1.55) 0s;cursor:pointer;left:0;margin:0;padding:0;position:absolute;top:0;z-index:20}
        .social-icons__nav:after,.social-icons__nav:before{content:"";display:table}
        .social-icons__nav:after{clear:both}
        .social-icons__item{box-sizing:border-box;float:left;height:40px;list-style:none;overflow:visible;padding:11px 0 0;position:relative;width:100%}
        .social-icons__item:hover .social-icons__button{opacity:.5}
        .social-icons__item:hover .social-icons__frame{opacity:1;transform:translateX(13px);left:32px}
        .social-icons__item:hover .social-icons__frame a,.social-icons__item:hover .social-icons__frame img{height:auto}
        .social-icons__button{display:block;float:left;overflow:auto;width:100%;z-index:1}
        .social-icons__button img{margin:auto}
        .social-icons__frame{box-sizing:border-box;opacity:0;transform:translateX(0);transition:opacity .3s ease 0s,transform .3s;left:-340px;margin:-11px auto 0;overflow:hidden;padding:0 16px;position:absolute;top:50%;width:auto;z-index:20}
        .social-icons__frame a,.social-icons__frame img{margin:auto}
        .social-icons__frame.fb-frame .fb-like{height:20px;line-height:0;overflow:hidden}
        .social-icons__frame.youtube-frame{line-height:0;margin:-11px auto;width:auto}
        .social-icons__frame.soundcloud-iframe{margin-top:-35px}
        .ig-b-{display:inline-block}
        .ig-b- img{visibility:hidden}
        .ig-b-:hover{background-position:0 -60px}
        .ig-b-:active{background-position:0 -120px}
        .ig-b-v-24{background-position:50%;background-size:cover;background:url(//badges.instagram.com./static/images/ig-badge-view-sprite-24.png);height:24px;width:137px}
        .page__header{width:214px;box-sizing:border-box;padding:2px}
        .page__header.page__blog-item,.page__header.page__program-item{box-sizing:border-box;width:272px}
        .page__header.page__blog-item{padding:0}
        .page__header-title{box-sizing:border-box;
            font-family:Basis Grotesque Pro Regular,Arial,
            sans-serif;font-weight:400;background:#f9f6ef;color:#000;
            font-size:35px;line-height:52px;overflow:hidden;padding-left:10px;
            text-transform:uppercase; text-align:center; }
        /*.page__header-title.page__info{font-size:20px}*/
        .page__header-title.page__info{font-size:17px}
        .page__header-title.page__program{box-sizing:border-box;width:214px;height:52px;left:2px;font-size:30px}
        .page__header-title.page__program-item{font-size:28px}
        .page__header-title.page__blog-item{font-size:31px;padding-left:0}
        .page__header-title.page__gym{font-size:23px}
        .page__header-subtitle{font-family:Basis Grotesque Pro Regular,Arial,sans-serif;font-weight:400;height:52px;right:2px;background:#f9f6ef;color:#000;font-size:15px;line-height:54px;margin-top:2px;overflow:hidden;position:absolute;text-align:center;text-indent:10px;text-transform:uppercase;display:block}
        .page__header-subtitle.page__program-item{box-sizing:border-box;height:160px;width:268px;font-size:21px;line-height:50px;padding:5px 15px;position:static;text-align:left;text-indent:0}
        .page__header-subtitle.page__blog{box-sizing:border-box;font-size:20px;line-height:28px;padding:11px 15px;position:absolute;text-align:center;text-indent:0;display:none}
        .page__header-extra-title{font-family:Basis Grotesque Pro Mono,Arial,sans-serif;font-weight:400;height:52px;right:2px;background:#f9f6ef;color:#292829;font-size:15px;line-height:50px;margin-top:2px;overflow:hidden;position:absolute;text-align:center;text-transform:uppercase}
        .page__blog .page__header-extra-title{right:2px}
        .page__blog .page__header-extra-title.page__header-extra-title--2,.page__info .page__header-extra-title.page__header-extra-title--2{right:164px}
        .page__header-extra-title.page__program{display:none}
        .page__header-extra-title.page__program-item{box-sizing:border-box;width:106px;top:216px;left:2px;position:static}
        .page__header-extra-title.page__blog-item{display:block}
        .page__header-extra-title-link{text-decoration:underline}
        .page__header-extra-title-link:hover{text-decoration:none}
        .page__header-extra-title-second{font-family:Basis Grotesque Pro Mono,Arial,sans-serif;font-weight:400;height:52px;right:2px;background:#f9f6ef;color:#292829;font-size:13px;line-height:50px;margin-top:2px;overflow:hidden;position:absolute;text-align:center}
        .page__header-extra-title-second.page__program-item{top:216px;right:2px;float:right;position:static}
        .page__header-image{height:214px;width:268px;display:block;position:relative}
        .page__blog .page__header-image{height:160px;width:100%;border:none}
        .grid__header-image{height:268px;width:272px;background:#f9f6ef;position:relative}
        .grid__header-image .background__container-animation{margin-top:54px}
        .page__header-go-back{box-sizing:border-box;font-family:Basis Grotesque Pro Regular,Arial,sans-serif;font-weight:400;background:#f9f6ef;display:block;font-size:14px;line-height:52px;margin:-2px 0 2px -2px;overflow:hidden;position:static;text-decoration:none;text-transform:uppercase}
        .page__header-go-back:hover .page__header-go-back-text{text-decoration:underline}
        .page__header-go-back.page__blog-item,.page__header-go-back.page__program-item{height:54px;box-sizing:border-box;width:272px;top:216px;left:2px}
        .page__header-cta-button{font-family:Basis Grotesque Pro Regular,Arial,sans-serif;font-weight:400;height:52px;box-sizing:border-box;width:272px;background:#f9f6ef;border-bottom:2px solid #292829;border-left:2px solid #292829;border-right:2px solid #292829;font-size:18px;line-height:36px;overflow:hidden;padding:8px 20px;text-align:center;text-transform:uppercase;z-index:2}
        .page__header-cta-button-link{color:#b64b43}
        .page__header-cta-button-link:hover{text-decoration:none}
        .soundcloud{margin-top:52px;box-sizing:border-box;height:110px;border:2px solid #292829}
        .soundcloud:hover{cursor:pointer}
        .soundcloud:hover .soundcloud__image{animation:a 10s linear infinite}
        .soundcloud:hover .soundcloud__play-button:after{border-left-color:#292829}
        .soundcloud__image{background-size:400px 250px;float:left;height:106px}
        .soundcloud__play-button{border-right:2px solid #292829;bottom:0;height:106px;left:0;position:relative;right:0;top:0;width:104px;z-index:3}
        .soundcloud__play-button:before{border-bottom:13px solid transparent;border-left:17px solid #292829;border-top:13px solid transparent;margin:-13px 0 0 -5px}
        .soundcloud__play-button:after,.soundcloud__play-button:before{border-right:0 solid transparent;content:"";height:0;left:50%;position:absolute;top:50%;width:0;z-index:10}
        .soundcloud__play-button:after{border-bottom:9px solid transparent;border-left:11px solid #f9f6ef;border-top:9px solid transparent;margin:-9px 0 0 -3px}
        .soundcloud__title{font-family:Basis Grotesque Pro Mono,Arial,sans-serif;font-weight:400;display:inline-block;font-size:12px;height:106px;line-height:106px;vertical-align:top;width:150px}.soundcloud__title-content{overflow:hidden;padding-left:13px;text-overflow:ellipsis;white-space:nowrap}.video__component-iframe{height:100%;left:0;position:absolute;width:100%}.video-item__video-container{margin-top:52px;box-sizing:border-box;height:164px;border:2px solid #292829;position:relative}.video-item__video-container:hover{cursor:pointer}.video-item__video-container:hover .video__image{animation:a 10s linear infinite}.video-item__video-container:hover .video__play-button:after{border-left-color:#292829}
        .video__image{background-size:750px 500px;height:100%;position:relative}.video__play-button{box-sizing:border-box;height:160px;bottom:0;left:0;position:relative;right:0;top:0;width:100%;z-index:3}.video__play-button:before{border-bottom:23px solid transparent;border-left:30px solid #292829;border-top:23px solid transparent;margin:-38px 0 0 -10px}.video__play-button:after,.video__play-button:before{border-right:0 solid transparent;content:"";height:0;left:50%;position:absolute;top:50%;width:0;z-index:10}.video__play-button:after{border-bottom:19px solid transparent;border-left:24px solid #f9f6ef;border-top:19px solid transparent;margin:-34px 0 0 -8px}.video__title{box-sizing:border-box;font-family:Basis Grotesque Pro Mono,Arial,sans-serif;font-weight:400;bottom:10px;font-size:12px;padding:0 10px;position:absolute;vertical-align:top;width:100%}
        .video__title-content{background:#f9f6ef;border:2px solid #292829;overflow:hidden;padding:12px 13px;text-overflow:ellipsis;white-space:nowrap}.program__list-container-home{margin-top:52px}.program__container{display:block;margin:47px auto 0;position:relative;width:268px}.program__container:after,.program__container:before{content:"";display:table}.program__container:after{clear:both}.program__container-image{display:none}.program__grid{background-image:url(./static/grid.svg);background-repeat:no-repeat;background-repeat:repeat;height:276px;left:0;position:absolute;top:0;width:221px;background-position:0 0;background-size:110px 110px}.program__grid-image{display:none}.program__grid-title-text{box-sizing:border-box;display:none;padding-left:10px;width:100%}.program__grid-title-text:first-child{display:block}.program__container-content{margin-top:54px}
        .program__month-title{font-size:32px;line-height:40px;margin-bottom:25px;text-transform:uppercase}.program__item-container+.program__item-container{margin-top:33px}.program__item-container+.program__month-title{margin-top:70px}.program__item-date-container{width:100%}.program__item-date{font-family:Basis Grotesque Pro Regular,Arial,sans-serif;font-weight:400;font-size:18px;line-height:27px;text-transform:uppercase}.program__item-title{font-size:13px;line-height:20px;margin-top:15px;width:100%}.program__item-link{text-decoration:none}
        .program__item-link:hover{color:#757575}.program__item-link-category,.program__item-tickets{text-transform:uppercase}.program__item-tickets{color:#b64b43;display:block;font-size:15px;line-height:17px;margin-top:15px;width:100%}.program__item-tickets:hover{color:#fa756b}.program__item-soldout{display:block;font-size:15px;line-height:17px;margin-top:15px;text-transform:uppercase;width:100%}.program__item-meta-info{font-family:Basis Grotesque Pro Mono,Arial,sans-serif;font-weight:400;font-size:12px;line-height:14px;margin-top:11px}.program__list-full-program-link{display:block;font-size:16px;line-height:18px;margin-top:30px;text-transform:uppercase}.program__list-container-home{padding-bottom:30px}
        .info__content-description-program__item{margin-top:54px}
        .ticket__iframe-container{height:1000px;width:100%}
        .info__container{display:block;margin:54px auto 0;position:relative;width:272px}
        .info__container:after,.info__container:before{content:"";display:table}
        .info__container:after{clear:both}
        .info__container-image{float:left;position:relative;width:100%}
        .info__grid{background-image:url(./static/grid.svg);background-repeat:no-repeat;background-repeat:repeat;height:276px;left:0;position:absolute;top:0;width:221px;background-position:0 0;background-size:110px 110px}
        .info__grid-title{font-family:Basis Grotesque Pro Regular,Arial,sans-serif;font-weight:400;
            background:#f9f6ef;border-bottom:2px solid #292829;border-right:2px solid #292829;display:none;
            font-size:33px;line-height:46px;overflow:hidden;padding:8px 20px;position:absolute;text-transform:uppercase;
            z-index:2; text-align: center;}
        .info__grid-title.page__program-item{left:2px;top:272px;width:268px;box-sizing:border-box;height:54px;border-left:2px solid #292829;border-right:0;display:none;font-size:20px;line-height:36px;position:static;text-align:center}
        .info__grid-title a:hover{text-decoration:none}
        .info__grid-title-text{box-sizing:border-box;display:none;line-height:55px;padding-left:10px;width:100%}
        .info__grid-title-text:first-child{display:block}
        .info__grid-title__sub-links-wrapper.page__blog{position:relative}
        .info__grid-image{display:none;float:left;position:relative;z-index:1}
        .info__container-content-grid{background-image:url(./static/grid.svg);background-size:54px 54px;background-repeat:repeat;padding-bottom:110px}
        .info__container-content-grid.page__blog{margin-top:32px;padding-bottom:0}
        .info__container-content-grid.page__info{position:static}
        .info__container-content{background:#f9f6ef;border-bottom:2px solid #292829}
        .page__blog .info__container-content{border:none}
        .info__content-title{display:none;font-size:30px;left:0;line-height:30px;text-transform:uppercase}
        .info__content-description{font-family:Basis Grotesque Pro Mono,Arial,sans-serif;font-weight:400;font-size:13px;line-height:20px;padding-bottom:54px}
        .info__content-button{transition:all .2s ease-out 0s;display:block;font-size:16px;line-height:18px;margin-top:45px;text-decoration:underline;text-transform:uppercase}
        .info__content-button:hover{color:#8f8f8f}
        .info__container-header{height:110px;background-image:url(static/grid.svg);background-repeat:repeat;background-size:54px 54px;width:100%}
        .title-page-header {height: 108px;}
        .info__container-header.page__program-item{height:auto}
        .info__container-header.page__blog-item{background:0 0;height:auto}
        .info__content-description-info{padding:54px 0}
        .info__content-description-info.exandeble .subtitle{font-size:20px;padding-bottom:20px;text-transform:uppercase}
        .info__content-description-info.exandeble .double_items__read_more_text{margin-bottom:16px;margin-top:16px}
        .info__content-description-info.exandeble .text_1{margin-bottom:16px}
        .info__content-description-info.exandeble #read-more{cursor:pointer}
        .reservation_widget{width:100%}
        .reservation_widget .subtitle{font-size:20px;margin-bottom:26px;padding-bottom:20px;text-transform:uppercase}
        .reservation_widget iframe{box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;display:block;margin:0 auto;overflow:hidden;width:100%}
        .info__content-description-blog__item{padding:54px 0}
        .double_items .double_items__subtitle{font-size:20px;padding-bottom:20px;text-transform:uppercase}
        .double_items .double_items__read_more_btn{display:block;padding:15px 0}
        .double_items .double_items__read_more_btn:hover{cursor:pointer;text-decoration:underline}
        .double_items .double_items__read_more_text,.double_items .hidden{display:none}
        .newsletter__section--form{margin-top:70px}
        .blog-item__container{display:table;float:left;margin-top:40px;width:100%}
        .blog-item__container-wrapper{float:left;position:relative;width:100%}
        @media(max-width:1220px) {.blog-item__container-wrapper{display: flex;position:relative;width:100%; flex-wrap:wrap}}
        .blog-item__container-wrapper .blog-item__info{box-sizing:border-box;display:table-cell;min-width:165px;padding-left:25px;vertical-align:top}
        .blog-item__container-wrapper .blog-item__intro{display:none}
        .blog-item__container-wrapper .blog-item__image{display:block;height:168px;position:relative;width:100%}
        .blog-item__container-wrapper .blog-item__category{text-transform:uppercase}
        .blog-item__container-wrapper .info__content-description-blog__item{margin-top:54px}
        .blog-item__title{font-family:Basis Grotesque Pro Regular,Arial,sans-serif;font-weight:400;font-size:20px;line-height:25px;text-transform:uppercase;width:100%}
        .blog-item__title.blog-item__title--highlight{font-size:30px;line-height:30px;padding-top:20px}
        .blog-item__title-link{display:block;text-decoration:none}
        .blog-item__title-link:hover{text-decoration:underline}
        .blog-item__title-link.blog-item__title-link--highlight{text-decoration:none}
        .blog-item__link{font-family:Basis Grotesque Pro Mono,Arial,sans-serif;font-weight:400;color:#292829;float:left;font-size:12px;line-height:14px;margin-top:10px;width:100%}
        .blog-item__link:hover{text-decoration:none}
        .blog-item__link.blog-item__link--highlight{font-size:14px;line-height:17px}
        .blog-item-highlight__container{height:272px;display:table;float:left;margin-top:40px;position:relative;width:100%}
        .blog-item-highlight__category{font-family:Basis Grotesque Pro Mono,Arial,sans-serif;font-weight:400;color:#292829;font-size:12px;line-height:14px}
        .blog-item-highlight__image{height:253px;left:30px;position:absolute;width:243px;z-index:0}
        .blog-item-highlight__info{margin-top:76px;position:relative;z-index:1}
        .blog-item-highlight__title{font-family:Basis Grotesque Pro Regular,Arial,sans-serif;font-weight:400;font-size:25px;line-height:30px;margin-top:10px;text-transform:uppercase}
        .blog-item-highlight__title-link{text-decoration:none}
        .blog-item-highlight__title-link:hover{text-decoration:underline}
        .theme__dark-gray{background:#28282a;color:#f5f5ea}
        .theme__dark-gray .header__logo{background-image:url(./static/logo_dark-gray.svg);background-repeat:no-repeat}
        .theme__dark-gray .grid,.theme__dark-gray .info__container-content-grid,.theme__dark-gray .info__container-header{background-image:url(./static/grid_dark-gray.svg);background-repeat:repeat;background-size:54px 54px}
        .theme__dark-gray .grid__header-image,.theme__dark-gray .grid__top:after,.theme__dark-gray .grid__top:before,.theme__dark-gray .info__container-content,.theme__dark-gray .info__grid-title,.theme__dark-gray .navigation__item,.theme__dark-gray .page__header-cta-button,.theme__dark-gray .page__header-extra-title,.theme__dark-gray .page__header-extra-title-second,.theme__dark-gray .page__header-go-back,.theme__dark-gray .page__header-subtitle,.theme__dark-gray .page__header-title,.theme__dark-gray .video__title-content{background:#28282a;border-color:#f5f5ea!important}
        .theme__dark-gray .navigation__trigger:after,.theme__dark-gray .navigation__trigger:before{background:#f5f5ea}
        .theme__dark-gray .navigation__item,.theme__dark-gray .page__header-extra-title,.theme__dark-gray .page__header-extra-title-second,.theme__dark-gray .page__header-subtitle,.theme__dark-gray .page__header-title,.theme__dark-gray a{color:#f5f5ea}
        .theme__dark-gray .page__header-cta-button-link{color:#d79092}
        .theme__dark-gray .page__header-pattern,.theme__dark-gray .soundcloud,.theme__dark-gray .soundcloud__play-button,.theme__dark-gray .video-item__video-container{border-color:#f5f5ea!important}
        .theme__dark-gray .video__play-button:before{border-left-color:#f5f5ea}
        .theme__dark-gray .video__play-button:after,.theme__dark-gray .video__play-button:hover:before{border-left-color:#28282a}
        .theme__dark-gray .soundcloud__play-button:before,.theme__dark-gray .video__play-button:hover:after{border-left-color:#f5f5ea}
        .theme__dark-gray .soundcloud:hover .soundcloud__play-button:before,.theme__dark-gray .soundcloud__play-button:after{border-left-color:#28282a}
        .theme__dark-gray .soundcloud:hover .soundcloud__play-button:after{border-left-color:#f5f5ea}
        .theme__dark-gray .arrows__pattern{background-image:url(./static/arrows_dark_gray.png);background-repeat:no-repeat}
        .theme__dark-gray .circles__pattern{background-image:url(./static/circles_dark_gray.png);background-repeat:no-repeat}
        .theme__dark-gray .dots__pattern{background-image:url(./static/dots_dark_gray.png);background-repeat:no-repeat}
        /*.theme__dark-gray .stairs__pattern{background-image:url(./static/dark_gray.png);background-repeat:no-repeat}*/
        .theme__dark-gray .waves__pattern{background-image:url(./static/waves_dark_gray.png);background-repeat:no-repeat}.theme__dark-gray .windows__pattern{background-image:url(./static/windows_dark_gray.png);background-repeat:no-repeat}
        .theme__dark-gray .pattern{background-image:url(./static/waves_dark_gray.png);background-repeat:no-repeat}
        .theme__dark-gray.pattern__arrows .pattern{background-image:url(./static/arrows_dark_gray.png);background-repeat:no-repeat}
        .theme__dark-gray.pattern__circles .pattern{background-image:url(./static/circles_dark_gray.png);background-repeat:no-repeat}
        .theme__dark-gray.pattern__dots .pattern{background-image:url(./static/dots_dark_gray.png);background-repeat:no-repeat}
        .theme__dark-gray.pattern__lines .pattern{background-image:url(./static/lines_dark_gray.png);background-repeat:no-repeat}
        .theme__dark-gray.pattern__stream .pattern{background-image:url(./static/stream_dark_gray.png);background-repeat:no-repeat}
        .theme__dark-gray.pattern__waves .pattern{background-image:url(./static/waves_dark_gray.png);background-repeat:no-repeat}
        .theme__dark-gray.pattern__windows .pattern{background-image:url(./static/windows_dark_gray.png);background-repeat:no-repeat}
        .theme__red{background:#f7f3ed;color:#d23232}
        .theme__red .header__logo{background-image:url(./static/logo_red.svg);background-repeat:no-repeat}
        .theme__red .grid,.theme__red .info__container-content-grid,.theme__red .info__container-header{background-image:url(./static/grid_red.svg);background-repeat:repeat;background-size:54px 54px}
        .theme__red .grid__header-image,.theme__red .grid__top:after,.theme__red .grid__top:before,.theme__red .info__container-content,.theme__red .info__grid-title,.theme__red .navigation__item,.theme__red .page__header-cta-button,.theme__red .page__header-extra-title,.theme__red .page__header-extra-title-second,.theme__red .page__header-go-back,.theme__red .page__header-subtitle,.theme__red .page__header-title,.theme__red .video__title-content{background:#f7f3ed;border-color:#d23232!important}
        .theme__red .navigation__trigger:after,.theme__red .navigation__trigger:before{background:#d23232}
        .theme__red .navigation__item,.theme__red .page__header-extra-title,.theme__red .page__header-extra-title-second,.theme__red .page__header-subtitle,.theme__red .page__header-title,.theme__red a{color:#d23232}
        .theme__red .page__header-cta-button-link{color:#28282a}
        .theme__red .page__header-pattern,.theme__red .soundcloud,.theme__red .soundcloud__play-button,.theme__red .video-item__video-container{border-color:#d23232!important}
        .theme__red .video__play-button:before{border-left-color:#d23232}
        .theme__red .video__play-button:after,.theme__red .video__play-button:hover:before{border-left-color:#f7f3ed}
        .theme__red .soundcloud__play-button:before,.theme__red .video__play-button:hover:after{border-left-color:#d23232}
        .theme__red .soundcloud:hover .soundcloud__play-button:before,.theme__red .soundcloud__play-button:after{border-left-color:#f7f3ed}
        .theme__red .soundcloud:hover .soundcloud__play-button:after{border-left-color:#d23232}
        .theme__red .arrows__pattern{background-image:url(./static/arrows_red.png);background-repeat:no-repeat}
        .theme__red .circles__pattern{background-image:url(./static/circles_red.png);background-repeat:no-repeat}
        .theme__red .dots__pattern{background-image:url(./static/dots_red.png);background-repeat:no-repeat}
        /*.theme__red .stairs__pattern{background-image:url(./static/red.png);background-repeat:no-repeat}*/
        .theme__red .waves__pattern{background-image:url(./static/waves_red.png);background-repeat:no-repeat}
        .theme__red .windows__pattern{background-image:url(./static/windows_red.png);background-repeat:no-repeat}
        .theme__red .pattern{background-image:url(./static/waves_red.png);background-repeat:no-repeat}
        .theme__red.pattern__arrows .pattern{background-image:url(./static/arrows_red.png);background-repeat:no-repeat}
        .theme__red.pattern__circles .pattern{background-image:url(./static/circles_red.png);background-repeat:no-repeat}
        .theme__red.pattern__dots .pattern{background-image:url(./static/dots_red.png);background-repeat:no-repeat}
        .theme__red.pattern__lines .pattern{background-image:url(./static/lines_red.png);background-repeat:no-repeat}
        .theme__red.pattern__stream .pattern{background-image:url(./static/stream_red.png);background-repeat:no-repeat}
        .theme__red.pattern__waves .pattern{background-image:url(./static/waves_red.png);background-repeat:no-repeat}
        .theme__red.pattern__windows .pattern{background-image:url(./static/windows_red.png);background-repeat:no-repeat}
        .theme__pink{background:#d79092;color:#f7f3ed}
        .theme__pink .header__logo{background-image:url(./static/logo_pink.svg);background-repeat:no-repeat}
        .theme__pink .grid,.theme__pink .info__container-content-grid,.theme__pink .info__container-header{background-image:url(./static/grid_pink.svg);background-repeat:repeat;background-size:54px 54px}
        .theme__pink .grid__header-image,.theme__pink .grid__top:after,.theme__pink .grid__top:before,.theme__pink .info__container-content,.theme__pink .info__grid-title,.theme__pink .navigation__item,.theme__pink .page__header-cta-button,.theme__pink .page__header-extra-title,.theme__pink .page__header-extra-title-second,.theme__pink .page__header-go-back,.theme__pink .page__header-subtitle,.theme__pink .page__header-title,.theme__pink .video__title-content{background:#d79092;border-color:#f7f3ed!important}
        .theme__pink .navigation__trigger:after,.theme__pink .navigation__trigger:before{background:#f7f3ed}
        .theme__pink .navigation__item,.theme__pink .page__header-extra-title,.theme__pink .page__header-extra-title-second,.theme__pink .page__header-subtitle,.theme__pink .page__header-title,.theme__pink a{color:#f7f3ed}
        .theme__pink .page__header-cta-button-link{color:#28282a}
        .theme__pink .page__header-pattern,.theme__pink .soundcloud,.theme__pink .soundcloud__play-button,.theme__pink .video-item__video-container{border-color:#f7f3ed!important}
        .theme__pink .video__play-button:before{border-left-color:#f7f3ed}
        .theme__pink .video__play-button:after,.theme__pink .video__play-button:hover:before{border-left-color:#d79092}
        .theme__pink .soundcloud__play-button:before,.theme__pink .video__play-button:hover:after{border-left-color:#f7f3ed}.theme__pink .soundcloud:hover .soundcloud__play-button:before,.theme__pink .soundcloud__play-button:after{border-left-color:#d79092}
        .theme__pink .soundcloud:hover .soundcloud__play-button:after{border-left-color:#f7f3ed}
        .theme__pink .arrows__pattern{background-image:url(./static/arrows_pink.png);background-repeat:no-repeat}
        .theme__pink .circles__pattern{background-image:url(./static/circles_pink.png);background-repeat:no-repeat}
        .theme__pink .dots__pattern{background-image:url(./static/dots_pink.png);background-repeat:no-repeat}
        /*.theme__pink .stairs__pattern{background-image:url(./static/pink.png);background-repeat:no-repeat}*/
        .theme__pink .waves__pattern{background-image:url(./static/waves_pink.png);background-repeat:no-repeat}
        .theme__pink .windows__pattern{background-image:url(./static/windows_pink.png);background-repeat:no-repeat}
        .theme__pink .pattern{background-image:url(./static/waves_pink.png);background-repeat:no-repeat}
        .theme__pink.pattern__arrows .pattern{background-image:url(./static/arrows_pink.png);background-repeat:no-repeat}
        .theme__pink.pattern__circles .pattern{background-image:url(./static/circles_pink.png);background-repeat:no-repeat}
        .theme__pink.pattern__dots .pattern{background-image:url(./static/dots_pink.png);background-repeat:no-repeat}
        .theme__pink.pattern__lines .pattern{background-image:url(./static/lines_pink.png);background-repeat:no-repeat}
        .theme__pink.pattern__stream .pattern{background-image:url(./static/stream_pink.png);background-repeat:no-repeat}
        .theme__pink.pattern__waves .pattern{background-image:url(./static/waves_pink.png);background-repeat:no-repeat}
        .theme__pink.pattern__windows .pattern{background-image:url(./static/windows_pink.png);background-repeat:no-repeat}
        .theme__orange{background:#f7f3ed;color:#ff8c00}
        .theme__orange .header__logo{background-image:url(./static/logo_orange.svg);background-repeat:no-repeat}
        .theme__orange .grid,.theme__orange .info__container-content-grid,.theme__orange .info__container-header{background-image:url(./static/grid_orange.svg);background-repeat:repeat;background-size:54px 54px}
        .theme__orange .grid__header-image,.theme__orange .grid__top:after,.theme__orange .grid__top:before,.theme__orange .info__container-content,.theme__orange .info__grid-title,.theme__orange .navigation__item,.theme__orange .page__header-cta-button,.theme__orange .page__header-extra-title,.theme__orange .page__header-extra-title-second,.theme__orange .page__header-go-back,.theme__orange .page__header-subtitle,.theme__orange .page__header-title,.theme__orange .video__title-content{background:#f7f3ed;border-color:#ff8c00!important}
        .theme__orange .navigation__trigger:after,.theme__orange .navigation__trigger:before{background:#ff8c00}
        .theme__orange .navigation__item,.theme__orange .page__header-extra-title,.theme__orange .page__header-extra-title-second,.theme__orange .page__header-subtitle,.theme__orange .page__header-title,.theme__orange a{color:#ff8c00}
        .theme__orange .page__header-cta-button-link{color:#28282a}
        .theme__orange .page__header-pattern,.theme__orange .soundcloud,.theme__orange .soundcloud__play-button,.theme__orange .video-item__video-container{border-color:#ff8c00!important}
        .theme__orange .video__play-button:before{border-left-color:#ff8c00}
        .theme__orange .video__play-button:after,.theme__orange .video__play-button:hover:before{border-left-color:#f7f3ed}
        .theme__orange .soundcloud__play-button:before,.theme__orange .video__play-button:hover:after{border-left-color:#ff8c00}
        .theme__orange .soundcloud:hover .soundcloud__play-button:before,.theme__orange .soundcloud__play-button:after{border-left-color:#f7f3ed}
        .theme__orange .soundcloud:hover .soundcloud__play-button:after{border-left-color:#ff8c00}
        .theme__orange .arrows__pattern{background-image:url(./static/arrows_orange.png);background-repeat:no-repeat}
        .theme__orange .circles__pattern{background-image:url(./static/circles_orange.png);background-repeat:no-repeat}
        .theme__orange .dots__pattern{background-image:url(./static/dots_orange.png);background-repeat:no-repeat}
        /*.theme__orange .stairs__pattern{background-image:url(./static/orange.png);background-repeat:no-repeat}*/
        .theme__orange .waves__pattern{background-image:url(./static/waves_orange.png);background-repeat:no-repeat}
        .theme__orange .windows__pattern{background-image:url(./static/windows_orange.png);background-repeat:no-repeat}
        .theme__orange .pattern{background-image:url(./static/waves_orange.png);background-repeat:no-repeat}
        .theme__orange.pattern__arrows .pattern{background-image:url(./static/arrows_orange.png);background-repeat:no-repeat}
        .theme__orange.pattern__circles .pattern{background-image:url(./static/circles_orange.png);background-repeat:no-repeat}
        .theme__orange.pattern__dots .pattern{background-image:url(./static/dots_orange.png);background-repeat:no-repeat}
        .theme__orange.pattern__lines .pattern{background-image:url(./static/lines_orange.png);background-repeat:no-repeat}
        .theme__orange.pattern__stream .pattern{background-image:url(./static/stream_orange.png);background-repeat:no-repeat}
        .theme__orange.pattern__waves .pattern{background-image:url(./static/waves_orange.png);background-repeat:no-repeat}
        .theme__orange.pattern__windows .pattern{background-image:url(./static/windows_orange.png);background-repeat:no-repeat}
        @media (-webkit-min-device-pixel-ratio:1.3),(min--moz-device-pixel-ratio:1.3),(min-device-pixel-ratio:1.3),(min-resolution:1.3dppx){.grid{background-image:url(./static/grid@2x.svg)}
        .arrows__pattern{background-image:url(./static/arrows_pattern@2x.png)}
        .circles__pattern{background-image:url(./static/circles_pattern@2x.png)}
        .dots__pattern{background-image:url(./static/dots_pattern@2x.png)}
        .lines__pattern{background-image:url(./static/lines_pattern@2x.png)}
        .stream__pattern{background-image:url(./static/stream_pattern@2x.png)}
        .waves__pattern{background-image:url(./static/waves_pattern@2x.png)}
        .windows__pattern{background-image:url(./static/windows_pattern@2x.png)}
        .pattern{background-image:url(./static/waves_pattern@2x.png)}
        .pattern__arrows .pattern{background-image:url(./static/arrows_pattern@2x.png)}
        .pattern__circles .pattern{background-image:url(./static/circles_pattern@2x.png)}
        .pattern__dots .pattern{background-image:url(./static/dots_pattern@2x.png)}
        .pattern__lines .pattern{background-image:url(./static/lines_pattern@2x.png)}
        .pattern__stream .pattern{background-image:url(./static/stream_pattern@2x.png)}
        .pattern__waves .pattern{background-image:url(./static/waves_pattern@2x.png)}
        .pattern__windows .pattern{background-image:url(./static/windows_pattern@2x.png)}
        .header__logo{background-position:50%;background-size:cover;background-image:url(./static/logo@2x.svg)}
/*        .form__input--container input[type=checkbox]:checked+label:before{background-position:50%;background-size:cover;background-image:url(./static/check_icon@2x.png)}
        .webkit .form__select--container .form__select+.form__label:before{background-position:50%;background-size:cover;background-image:url(./static/select_icon@2x.png)}*/
        .info__grid,.program__grid{background-position:50%;background-size:cover}
        .info__container-content-grid,.info__container-header,.info__grid,.program__grid{background-image:url(./static/grid@2x.svg)}
        .theme__dark-gray .header__logo{background-position:50%;background-size:cover;background-image:url(./static/logo_dark-gray@2x.svg)}
        .theme__dark-gray .grid,.theme__dark-gray .info__container-content-grid,.theme__dark-gray .info__container-header{background-image:url(./static/grid_dark-gray@2x.svg)}
        .theme__dark-gray .arrows__pattern{background-image:url(./static/arrows_dark_gray@2x.png)}
        .theme__dark-gray .circles__pattern{background-image:url(./static/circles_dark_gray@2x.png)}
        .theme__dark-gray .dots__pattern{background-image:url(./static/dots_dark_gray@2x.png)}
        /*.theme__dark-gray .stairs__pattern{background-image:url(./static/dark_gray@2x.png)}.theme__dark-gray .waves__pattern{background-image:url(./static/waves_dark_gray@2x.png)}*/
        .theme__dark-gray .windows__pattern{background-image:url(./static/windows_dark_gray@2x.png)}
        .theme__dark-gray .pattern{background-image:url(./static/waves_dark_gray@2x.png)}
        .theme__dark-gray.pattern__arrows .pattern{background-image:url(./static/arrows_dark_gray@2x.png)}
        .theme__dark-gray.pattern__circles .pattern{background-image:url(./static/circles_dark_gray@2x.png)}
        .theme__dark-gray.pattern__dots .pattern{background-image:url(./static/dots_dark_gray@2x.png)}
        .theme__dark-gray.pattern__lines .pattern{background-image:url(./static/lines_dark_gray@2x.png)}
        .theme__dark-gray.pattern__stream .pattern{background-image:url(./static/stream_dark_gray@2x.png)}
        .theme__dark-gray.pattern__waves .pattern{background-image:url(./static/waves_dark_gray@2x.png)}
        .theme__dark-gray.pattern__windows .pattern{background-image:url(./static/windows_dark_gray@2x.png)}
        .theme__red .header__logo{background-position:50%;background-size:cover;background-image:url(./static/logo_red@2x.svg)}
        .theme__red .grid,.theme__red .info__container-content-grid,.theme__red .info__container-header{background-image:url(./static/grid_red@2x.svg)}
        .theme__red .arrows__pattern{background-image:url(./static/arrows_red@2x.png)}
        .theme__red .circles__pattern{background-image:url(./static/circles_red@2x.png)}
        .theme__red .dots__pattern{background-image:url(./static/dots_red@2x.png)}
        /*.theme__red .stairs__pattern{background-image:url(./static/red@2x.png)}*/
        .theme__red .waves__pattern{background-image:url(./static/waves_red@2x.png)}
        .theme__red .windows__pattern{background-image:url(./static/windows_red@2x.png)}
        .theme__red .pattern{background-image:url(./static/waves_red@2x.png)}
        .theme__red.pattern__arrows .pattern{background-image:url(./static/arrows_red@2x.png)}
        .theme__red.pattern__circles .pattern{background-image:url(./static/circles_red@2x.png)}
        .theme__red.pattern__dots .pattern{background-image:url(./static/dots_red@2x.png)}
        .theme__red.pattern__lines .pattern{background-image:url(./static/lines_red@2x.png)}
        .theme__red.pattern__stream .pattern{background-image:url(./static/stream_red@2x.png)}
        .theme__red.pattern__waves .pattern{background-image:url(./static/waves_red@2x.png)}
        .theme__red.pattern__windows .pattern{background-image:url(./static/windows_red@2x.png)}
        .theme__pink .header__logo{background-position:50%;background-size:cover;background-image:url(./static/logo_pink@2x.svg)}
        .theme__pink .grid,.theme__pink .info__container-content-grid,.theme__pink .info__container-header{background-image:url(./static/grid_pink@2x.svg)}
        .theme__pink .arrows__pattern{background-image:url(./static/arrows_pink@2x.png)}
        .theme__pink .circles__pattern{background-image:url(./static/circles_pink@2x.png)}
        .theme__pink .dots__pattern{background-image:url(./static/dots_pink@2x.png)}
        /*.theme__pink .stairs__pattern{background-image:url(./static/pink@2x.png)}*/
        .theme__pink .waves__pattern{background-image:url(./static/waves_pink@2x.png)}
        .theme__pink .windows__pattern{background-image:url(./static/windows_pink@2x.png)}
        .theme__pink .pattern{background-image:url(./static/waves_pink@2x.png)}
        .theme__pink.pattern__arrows .pattern{background-image:url(./static/arrows_pink@2x.png)}
        .theme__pink.pattern__circles .pattern{background-image:url(./static/circles_pink@2x.png)}
        .theme__pink.pattern__dots .pattern{background-image:url(./static/dots_pink@2x.png)}
        .theme__pink.pattern__lines .pattern{background-image:url(./static/lines_pink@2x.png)}
        .theme__pink.pattern__stream .pattern{background-image:url(./static/stream_pink@2x.png)}
        .theme__pink.pattern__waves .pattern{background-image:url(./static/waves_pink@2x.png)}
        .theme__pink.pattern__windows .pattern{background-image:url(./static/windows_pink@2x.png)}
        .theme__orange .header__logo{background-position:50%;background-size:cover;background-image:url(./static/logo_orange@2x.svg)}
        .theme__orange .grid,.theme__orange .info__container-content-grid,.theme__orange .info__container-header{background-image:url(./static/grid_orange@2x.svg)}
        .theme__orange .arrows__pattern{background-image:url(./static/arrows_orange@2x.png)}
        .theme__orange .circles__pattern{background-image:url(./static/circles_orange@2x.png)}
        .theme__orange .dots__pattern{background-image:url(./static/dots_orange@2x.png)}
        /*.theme__orange .stairs__pattern{background-image:url(./static/orange@2x.png)}*/
        .theme__orange .waves__pattern{background-image:url(./static/waves_orange@2x.png)}
        .theme__orange .windows__pattern{background-image:url(./static/windows_orange@2x.png)}
        .theme__orange .pattern{background-image:url(./static/waves_orange@2x.png)}
        .theme__orange.pattern__arrows .pattern{background-image:url(./static/arrows_orange@2x.png)}
        .theme__orange.pattern__circles .pattern{background-image:url(./static/circles_orange@2x.png)}
        .theme__orange.pattern__dots .pattern{background-image:url(./static/dots_orange@2x.png)}
        .theme__orange.pattern__lines .pattern{background-image:url(./static/lines_orange@2x.png)}
        .theme__orange.pattern__stream .pattern{background-image:url(./static/stream_orange@2x.png)}
        .theme__orange.pattern__waves .pattern{background-image:url(./static/waves_orange@2x.png)}
        .theme__orange.pattern__windows .pattern{background-image:url(./static/windows_orange@2x.png)}
    }
    @media only screen and (-webkit-min-device-pixel-ratio:2),only screen and (min--moz-device-pixel-ratio:2),only screen and (min-device-pixel-ratio:2),only screen and (min-resolution:2dppx),only screen and (min-resolution:192dpi){.ig-b-v-24{background-image:url(//badges.instagram.com./static/images/ig-badge-view-sprite-24@2x.png);background-size:160px 178px}
}
@media only screen and (min-width:737px){
    .grid__container{display:block;left:0;margin:108px auto 0;position:absolute;top:0;width:100%;z-index:-1}
    .grid__wrapper{width:650px;margin:0 auto;position:relative}
    .navigation {display: flex; flex-flow: column wrap; justify-content:flex-end;list-style:none;margin:0;}
    .team-image-map {width: 80%; top: 4%; left: 10%; }
    .grid.grid__top{min-height:110px}
    .grid.grid__top:after{height:2px;width:2px}
    .page__home .page__header-pattern{height:160px;box-sizing:border-box;width:376px;right:56px;top:56px}
    .page__blog .page__header-pattern{display:none}
    .page__info .page__header-pattern{height:160px;box-sizing:border-box;width:376px;right:56px;top:56px}
    .page__program .page__header-pattern{height:160px;box-sizing:border-box;width:376px;top:-52px;z-index:2}
    .page__tickets .page__header-pattern{height:160px;box-sizing:border-box;width:376px;right:56px;top:56px}
    .page__header-pattern-second.page__program-item{height:160px;box-sizing:border-box;width:214px;right:2px;top:-106px;z-index:2}
    .page__header-pattern-second.page__blog-item{height:214px;box-sizing:border-box;width:214px;right:2px;top:-106px;z-index:2}
    .header__container{min-width:650px}
    .header__language{float:right;margin:18px 20px 0;position:static;text-transform:uppercase}
    .footer__container{margin:400px auto 50px;width:658px}
    .footer__row.footer__link-row{margin-top:0}
    .footer__item+.footer__item,.footer__link+.footer__link{margin-left:40px}
    .navigation__trigger{margin-top:18px}
    .teams__navigation{right:0;top:108px;width:272px}
    .teams__navigation-trigger{display: none;}
    .teams__container{right:0;top:108px;width:272px}
    .navigation__container {right: 0;top: 108px;width: 272px;}
    .block__section{padding:0 48px 50px}
    .page__header.page__blog-item,.page__header.page__program-item{width:auto}
    .page__header-title{height:106px;width:430px;left:164px;top:-52px;font-size:70px;line-height:106px;position:absolute}
    .page__header-title.page__info{font-size:37px}
    /*.page__header-title.page__info{font-size:42px}*/
    .page__header-title.page__program{width:324px;height:108px;left:2px;top:0;font-size:47px}
    .page__header-title.page__program,.page__header-title.page__program-item{box-sizing:border-box;border-right:2px solid #292829;border-top:2px solid #292829;z-index:3}
    .page__header-title.page__program-item{width:378px;height:56px;right:108px;top:-54px;border-bottom:2px solid #292829;font-size:40px;left:auto;line-height:54px}
    .page__header-title.page__blog{height:106px;width:268px;right:164px;left:auto}
    .page__header-title.page__blog-item{width:378px;box-sizing:border-box;height:110px;right:108px;top:-54px;border-bottom:2px solid #292829;border-right:2px solid #292829;border-top:2px solid #292829;font-size:32px;left:auto;line-height:54px;padding-left:10px;z-index:3}
    .page__header-title.page__tickets{height:106px;width:322px}
    .page__header-title.page__gym{font-size:48px}
    .page__header-subtitle{height:52px;width:268px;left:56px;top:110px;font-size:20px;line-height:52px;margin-top:0;position:absolute}
    .page__header-subtitle.page__program-item{box-sizing:border-box;width:324px;left:110px;top:2px;border-right:2px solid #292829;font-size:28px;position:absolute;z-index:2}
    .page__header-subtitle.page__blog{height:106px;width:52px;left:2px;top:56px;display:block}
    .page__header-extra-title{font-size:18px;line-height:52px;padding:0 25px;top:-108px}
    .page__header-extra-title.page__program{display:block}
    .page__header-extra-title.page__program-item{border-left:2px solid #292829;width:auto}
    .page__header-extra-title.page__blog-item,.page__header-extra-title.page__program-item{box-sizing:border-box;height:54px;right:2px;top:-108px;border-bottom:2px solid #292829;left:auto;position:absolute;z-index:3}
    .page__header-extra-title.page__blog-item{display:block;padding:0 25px}
    .page__header-extra-title-second{font-size:18px;line-height:52px;margin-top:0;top:-108px}
    .page__header-extra-title-second.page__program-item{top:164px;right:56px;padding:0 25px;position:absolute}
    .page__blog .page__header-image,.page__header-image{height:538px;width:538px;display:block}
    .grid__header-image{box-sizing:border-box;height:214px;width:322px;top:218px;left:2px;position:absolute;z-index:1}
    .grid__header-image.page__blog-item{top:164px; position: relative;}
    .grid__header-image .background__container-animation{margin-top:0}
    .page__header-go-back.page__program-item{top:164px}
    .page__header-go-back.page__blog-item,.page__header-go-back.page__program-item{height:52px;box-sizing:border-box;width:106px;left:56px;margin:0;padding-left:10px;position:absolute;z-index:3}
    .page__header-go-back.page__blog-item{top:56px}
    .page__header-cta-button{right:2px;top:218px;width:214px;box-sizing:border-box;height:52px;border:0;display:block;font-size:24px;line-height:38px;position:absolute}
    .soundcloud{width:218px}
    .soundcloud__title{width:100px}
    .video-item__video-container{width:218px}
    .program__list-container-home{margin-top:0}
    .program__container{width:658px}
    .program__container-image{display:none}
    .program__grid{height:386px;width:276px}
    .program__grid-title-text{display:none}
    .program__container-content{margin:0}
    .program__month-title{font-size:40px;margin-bottom:40px}
    .program__item-container{display:table;width:100%}
    .program__item-date-container{display:inline-block;padding-right:20px;width:95px}
    .program__list-container-home .program__item-date-container{width:100px}
    .program__item--meta-container{display:inline-block;vertical-align:top;width:365px}
    .program__item-meta-main-info{display:table;width:100%}
    .program__item-title{display:table-cell;margin-top:0;padding-top:4px;width:auto}
    .program__item-tickets{transition:all .2s ease-out 0s;display:inline}
    .program__item-soldout,.program__item-tickets{font-size:14px;line-height:16px;margin:0 0 0 10px}
    .program__item-soldout{display:table-cell;display:inline}
    .program__item-meta-info{display:table;margin-top:16px}
    .program__list-full-program-link{margin-left:130px}
    .program__list-container-home{padding-bottom:0}
    .info__content-description-program__item{margin-top:0;padding:20px}
    .info__container{width:650px}
    .info__container-image{float:right;width:330px}
    .info__grid{height:386px;width:276px}
    .info__grid-title{height:108px;display:block}
    .info__team-image{height: 161px;display: block;top: 52px}
    .info__grid-title,.info__grid-title.page__blog{left:56px;top:56px;width:270px;box-sizing:border-box}
    .info__grid-title.page__blog{height:162px;overflow:auto;padding:8px 20px;border-top:0;min-width:270px}
    .info__grid-title.page__program{display:none}
    .info__grid-title.page__program-item{width:270px;box-sizing:border-box;height:164px;top:270px;border-left:0;border-right:2px solid #292829;border-top:2px solid #292829;display:block;font-size:26px;position:absolute;text-align:left}
    .info__grid-title-text{display:none}
    .info__container-content-grid{margin-top:-2px}
    .info__container-content-grid.page__program-item{width:650px;margin-top:-216px;top:-2px;width:380px;float:right;padding-bottom:110px;position:relative}
    .info__container-content-grid.page__blog{margin-top:-2px;padding-bottom:110px}
    .info__container-content-grid.page__blog-item{width:650px;margin-top:-324px;top:-2px;width:380px;float:right;padding-bottom:110px;position:relative}
    .info__container-content{margin-top:0;margin-right:54px;width:542px;border:2px solid #292829;box-sizing:border-box;float:right}
    .info__container-content.page__home{padding:20px}
    .info__container-content.page__program{margin-left:54px;padding:20px}
    .info__container-content.page__program-item{width:326px;margin-right:0;float:right}
    .info__container-content.page__blog-item{width:326px;margin-right:0}
    .info__container-content.page__tickets{box-sizing:border-box;width:596px}
    .page__blog .info__container-content{border:2px solid}
    .info__container-header{height:218px}
    .info__container-header.page__program-item{height:488px}
    .info__container-header.page__blog-item{height:488px;background-image:url(./static/grid.svg);background-repeat:repeat;background-size:54px 54px; }
    .info__content-description-info{padding:54px}

    .reservation_widget .subtitle{padding:0 54px}
    .reservation_widget .extra__info{padding:0 54px;padding-top:10px}
    .reservation_widget iframe{padding:0 54px}
    .info__content-description-blog__item{padding:32px}
    .blog-item__container{margin-top:54px; margin-bottom:54px}
    .blog-item__container-wrapper .blog-item__info{min-width:383px;padding-left:50px}
    .blog-item__container-wrapper .blog-item__intro{font-family:Basis Grotesque Pro Mono,Arial,sans-serif;font-weight:400;color:#292829;display:block;font-size:12px;line-height:16px;margin-top:20px}
    .blog-item__container-wrapper .blog-item__image{height:275px;width:100%}
    .blog-item__container-wrapper .info__content-description-blog__item{margin-top:0;padding:20px}
    .blog-item__title{font-family:Basis Grotesque Pro Regular,Arial,sans-serif;font-weight:400;font-size:30px;line-height:40px}
    .blog-item__title.blog-item__title--highlight{display:none}
    .blog-item__link{font-size:16px;line-height:18px;margin-top:20px}
    .blog-item__link.blog-item__link--highlight{display:none}
    .blog-item-highlight__container{margin-top:54px}
    .blog-item-highlight__category{font-size:16px;line-height:18px}.blog-item-highlight__image{height:540px;left:110px;width:540px}
    .blog-item-highlight__info{margin-top:100px;min-height:440px}
    .blog-item-highlight__title{font-size:50px;line-height:50px;margin-top:15px}
     .team-button {font-size: 8px; padding: 4px;border: 1.5px solid black;}
    .team-button:hover {border: 1.5px solid #f9f6ef;}
    #team-button-kantoor {position:absolute; left:30%; top:8%;}
    #team-button-club {position:absolute; left:-10%; bottom:49%;}
    #team-button-cafe {position:absolute; left:75%; bottom:18%;}
    #team-button-restaurant {position:absolute; left:51%; bottom:49%}
    #team-button-klaslokaal {position:absolute; left:69%; top: 8%}
    #team-button-kunstlokaal {position:absolute; left:-24%; bottom:68%}
}
@media only screen and (min-width:737px) and (-webkit-min-device-pixel-ratio:1.3),only screen and (min-width:737px) and (min--moz-device-pixel-ratio:1.3),only screen and (min-width:737px) and (min-device-pixel-ratio:1.3),only screen and (min-width:737px) and (min-resolution:1.3dppx){.info__container-header.page__blog-item{background-image:url(./static/grid@2x.svg)}
}
@media only screen and (min-width:1024px){
    .grid__wrapper{width:866px}
    .header__container{min-width:866px}
    .footer__container{width:800px}
    .navigation__item{transition:all .1s ease-out 0s}
    .navigation {display: flex; flex-flow: row wrap; justify-content:flex-end;list-style:none;margin:0;}
    .navigation li{margin-left:10px}
    .form__select{height:40px;line-height:42px}
    .form__select.form__simple-select{font-size:14px;margin-top:-14px}
    .form__label--radio-container{margin-right:20px}
    .block__section{display:block;max-width:1000px;padding:0 105px 50px}
    .block__title{text-align:left}
    .social-icons__component{display:block;left:0;margin-top:-100px;position:fixed;top:50%;width:48px;z-index:10}
    .page__header-title{left:326px;height:106px;width:430px}
    .page__header-title.page__program{height:110px;box-sizing:border-box;width:486px;top:54px;left:56px;border-bottom:2px solid #292829;font-size:60px}
    .page__header-title.page__gym{box-sizing:border-box;width:538px;right:110px;font-size:60px;left:auto}
    .soundcloud{width:434px}
    .soundcloud__title{width:305px}
    .soundcloud__title-content{padding-left:20px}
    .video-item__video-container{width:434px;box-sizing:border-box;height:272px}
    .video__play-button{box-sizing:border-box;height:268px}
    .video__title-content{padding-left:20px}
    .program__container{margin:57px auto 0;width:882px}
    .program__container-image{display:block;float:left;position:relative;width:386px}
    .program__grid{height:606px;top:55px;width:386px}
    .program__grid-title{color:#fff;font-size:38px;line-height:40px;position:absolute;text-transform:uppercase;top:8px;z-index:2}
    .program__grid-title-text{display:block}
    .info__container{width:866px}
    .info__container-image{float:right;width:549px}
    .info__grid{height:661px;width:496px}
    .info__grid-title-text{display:block}
    .info__container-content-grid.page__info{width:866px}
    .info__container-content{margin-top:0;width:542px;border:2px solid #292829;float:left}
    .info__container-content.page__info{margin-left:54px}
    .page__blog .info__container-content{border:2px solid}
    .info__content-title{display:block}
    .info__container-header{height:326px}
    .blog-item__container{display:block;float:left;margin-right:54px;max-height:698px;max-width:216px;}
    .blog-item__container:nth-child(5),.blog-item__container:nth-child(7){clear:left}
    .blog-item__container-wrapper.page__blog .blog-item__container:nth-child(5){clear:left}
    .blog-item__container-wrapper .blog-item__info{min-width:0;padding-left:0;width:100%}
    .blog-item__container-wrapper .blog-item__image{height:216px;width:100%}
    .blog-item__title{margin-top:40px}
    .blog-item-highlight__container{left:542px;display:block;float:left;margin-right:55px;max-height:668px;max-width:432px;min-height:668px;position:absolute;top:754px}
    .team-button {font-size: 8px; padding: 4px;border: 1.5px solid black;}
    .team-button:hover {border: 1.5px solid #f9f6ef;}
     #team-button-kantoor {position:absolute; left:30%; top:8%;}
    #team-button-club {position:absolute; left:-10%; bottom:49%;}
    #team-button-cafe {position:absolute; left:75%; bottom:18%;}
    #team-button-restaurant {position:absolute; left:62%; bottom:49%}
    #team-button-klaslokaal {position:absolute; left:69%; top: 8%}
    #team-button-kunstlokaal {position:absolute; left:-24%; bottom:68%}

}
@media only screen and (min-width:1220px){
    .grid__wrapper{width:1082px}
    .grid.grid__top:after{display:none}
    .page__blog .page__header-pattern,.page__home .page__header-pattern{height:160px;box-sizing:border-box;width:268px;left:326px;top:218px;right:auto;z-index:2}
    .page__blog .page__header-pattern{display:block}
    .page__info .page__header-pattern{box-sizing:border-box;width:268px;left:326px;top:218px;right:auto;z-index:2}
    .page__program .page__header-pattern{box-sizing:border-box;height:214px;left:164px;top:2px;background-repeat:repeat}
    .page__header-pattern.page__program-item{height:162px;box-sizing:border-box;width:378px;left:108px;top:378px;border-left:2px solid #292829;border-top:2px solid #292829;z-index:4}
    .page__tickets .page__header-pattern{box-sizing:border-box;width:322px;left:110px;top:218px;right:auto;z-index:2}
    .page__header-pattern.page__header-dot-pattern-second{height:160px;box-sizing:border-box;width:376px;left:56px;top:380px}
    .page__header-pattern-second.page__blog-item,.page__header-pattern-second.page__program-item{height:214px;box-sizing:border-box;width:322px}
    .header__container{min-width:1082px}
    .header__language{margin:18px 0 0;position:absolute;right:0;top:-50px}
    .header__language .footer__icon-facebook,.header__language .footer__icon-instagram{display:inline-block;font-size:12px;margin-right:10px}
    .header__language .footer__icon-snapchat{display:inline-block;font-size:12px;margin-right:26px}
    .header__language--item{font-size:12px}
    
    .navigation__trigger{display:none}
    .navigation__container{display:block!important;float:left;margin:18px 0 0 55px;position:static;top:auto;width:auto}
    .navigation__container:after,.navigation__container:before{content:"";display:table}
    .navigation__container:after{clear:both}
    .navigation__item{border:0;float:left;font-size:14px;line-height:16px;padding:0;text-decoration:none;width:auto}
    .navigation__item+.navigation__item{margin-left:28px}
    .navigation__item.navigation__item-newsletter{font-family:Basis Grotesque Pro Mono,Arial,sans-serif;font-weight:400;font-size:12px;position:absolute;right:220px;text-transform:uppercase;top:-32px}
    .navigation__item.navigation__item-newsletter:before{content:"|";position:absolute;right:-15px}
    .navigation__item:last-child{border-bottom:0}
    .form__label--radio-container{margin-right:40px}
    .block__section{padding:0 105px 50px}
    .page__header-title{right:164px;left:auto;z-index:2}
    .page__header-title.page__program{height:110px;box-sizing:border-box;width:486px;top:54px;left:56px;border-bottom:2px solid #292829;font-size:70px}
    .page__header-title.page__program-item{font-size:75px;line-height:105px}
    .page__header-title.page__blog-item,.page__header-title.page__program-item{width:702px;box-sizing:border-box;height:110px;right:108px}
    .page__header-title.page__gym{box-sizing:border-box;width:538px}
    .page__header-subtitle{height:52px;width:322px;right:542px;top:218px;font-size:24px;left:auto;z-index:2;border:none}
    .page__header-subtitle.page__program-item{width:376px;left:272px;top:56px;border-right:0}
    .page__header-subtitle.page__blog{top:110px}
    .page__header-extra-title{font-size:25px}
    .page__header-extra-title.page__program-item{padding:0 15px}
    .page__header-extra-title.page__blog-item{padding:0 10px}
    .page__header-extra-title-second{font-size:25px;padding:0 25px}
    .page__header-extra-title-second.page__program-item{top:218px;right:56px;font-size:24px}
    .page__blog .page__header-image,.page__header-image{height:646px;width:430px}
    .grid__header-image{height:268px;width:430px;top:272px;left:56px;z-index:2}
    .page__header-go-back.page__blog-item,.page__header-go-back.page__program-item{top:56px;left:56px}
    .page__header-cta-button{right:56px;top:272px}
    .soundcloud{width:380px}
    .soundcloud__title{width:250px}
    .video-item__video-container{width:380px}
    .video__play-button:before{border-bottom:40px solid transparent;border-left:57px solid #292829;border-top:40px solid transparent;margin:-58px 0 0 -20px}
    .video__play-button:after{border-bottom:36px solid transparent;border-left:51px solid #f9f6ef;border-top:36px solid transparent;margin:-54px 0 0 -18px}
    .program__container{width:1100px}
    .program__container-image{width:496px}
    .program__grid{height:826px;width:496px}
    .program__grid-image{height:808px;width:376px;margin-top:54px;display:block;float:left;position:relative;z-index:1}
    .program__grid-title{font-size:72px;line-height:68px}
    .program__list-container-home .program__item--meta-container{width:265px}
    .info__container{width:1082px}
    .info__container-image{width:769px}
    .info__grid{height:826px;width:716px}
    .info__grid-title,.info__grid-title.page__blog{left:56px;top:110px;width:432px;box-sizing:border-box;height:162px}
    .info__grid-image{left:0;top:0;width:432px;box-sizing:border-box;height:324px;margin-top: 54px; display: block;}
    .info__grid-title.page__program{left:2px;top:326px;width:378px;box-sizing:border-box;height:162px;display:block;font-size:34px;line-height:48px}
    .info__grid-title.page__program-item{top:432px;font-size:32px;z-index:4}
    .info__grid-title.page__program-item,.info__grid-title.page__tickets{box-sizing:border-box;width:324px;left:56px}
    .info__grid-image{width:507px;height:920px}
    .info__grid-image.page__tickets{width:380px;height:542px}
    .info__container-content-grid{width:542px;float:right}
    .info__container-content-grid.page__home{top:110px;position:absolute;right:0;z-index:1}
    .info__container-content-grid.page__program{width:650px;margin-top:-486px;top:-2px;padding-bottom:110px;position:relative}
    .info__container-content-grid.page__program-item{width:650px;margin-top:-324px}
    .info__container-content-grid.page__blog{border-bottom:none;top:110px;position:absolute;right:0;z-index:1}
    .info__container-content-grid.page__blog-item{width:650px;margin-top:-486px}
    .info__container-content-grid.page__tickets{width:650px;margin-top:-270px;top:-2px;position:relative}
    .info__container-content-grid.page__info{width:542px;margin-top:-272px}
    .info__container-content{box-sizing:border-box;width:430px;margin-top:2px;margin-right:56px;border:0;float:right}
    .info__container-content.page__program{box-sizing:border-box;width:538px;top:2px;right:2px;margin-right:2px}
    .info__container-content.page__program-item{box-sizing:border-box;width:538px;margin-right:56px}
    .info__container-content.page__blog-item{box-sizing:border-box;width:538px;margin-right:2px;top:2px}
    .info__container-content.page__tickets{box-sizing:border-box;width:592px;margin-right:2px}
    .page__blog .info__container-content{border:none}
    .info__container-header{height:380px}
    .team-image-map {width: 52%; top: 4%; left: 26%; }
    .info__container-header.page__program{height:542px}
    .info__container-header.page__blog-item,.info__container-header.page__program-item{height:650px}

    .blog-item__container:nth-child(2){clear:right}
    .blog-item__container:nth-child(4){clear:left}
    .blog-item__container:nth-child(5){clear:none}
    .blog-item__container:nth-child(6){clear:left}
    .blog-item__container:nth-child(7){clear:none}

    .blog-item__container-wrapper.page__blog .blog-item__container:nth-child(3){clear:left}
    .blog-item__container-wrapper.page__blog .blog-item__container:nth-child(4),.blog-item__container-wrapper.page__blog .blog-item__container:nth-child(6){clear:none}
    .blog-item-highlight__container{max-width:660px;top:770px}
    .team-button {font-size: 8px; padding: 4px; border: 1.5px solid black;}
    .team-button:hover {border: 1.5px solid #f9f6ef;}
     #team-button-kantoor {position:absolute; left:40%; top:8%;}
    #team-button-club {position:absolute; left:17%; bottom:49%;}
    #team-button-cafe {position:absolute; left:70%; bottom:18%;}
    #team-button-restaurant {position:absolute; left:62%; bottom:49%}
    #team-button-klaslokaal {position:absolute; left:69%; top: 8%}
    #team-button-kunstlokaal {position:absolute; left:8%; bottom:68%}

}
@media only screen and (min-width:1500px){
    .grid__wrapper{width:1352px}
    .page__blog .page__header-pattern,.page__home .page__header-pattern,.page__info .page__header-pattern{box-sizing:border-box;width:376px;left:434px}
    .page__program .page__header-pattern{left:272px}
    .page__header-pattern.page__program-item{box-sizing:border-box;width:380px;border-right:2px solid #292829}
    .page__tickets .page__header-pattern{box-sizing:border-box;width:376px}
    .page__header-pattern.page__header-dot-pattern-second{left:164px}
    .page__header-pattern-second.page__blog-item,.page__header-pattern-second.page__program-item{box-sizing:border-box;width:430px}
    .header__container{min-width:1352px}
    .footer__container{width:1352px}
    .block__section{padding:0 0 50px}
    .page__header-title{font-size:70px}
    .page__header-title.page__program{height:110px;box-sizing:border-box;width:540px;font-size:80px}
    .page__header-title.page__blog-item,.page__header-title.page__program-item{right:108px}
    .page__header-subtitle{right:704px}
    .page__header-subtitle.page__program-item{left:434px}
    .page__header-subtitle.page__blog{left:110px}
    .page__header-extra-title-second.page__program-item{right:56px}
    .grid__header-image{height:322px;width:484px;top:218px}
    .page__header-cta-button{right:56px}
    .soundcloud{width:488px}
    .soundcloud__title{width:350px}
    .video-item__video-container{width:488px}
    .program__container{width:1352px}
    .program__container-image{width:771px}
    .program__grid{height:716px;top:165px;width:771px}
    .program__grid-image{width:592px}
    .info__container{width:1352px}
    .info__container-image{width:1044px}
    .info__grid{width:992px}
    .info__grid-title,.info__grid-title.page__blog{left:164px;top:110px;width:540px;box-sizing:border-box;height:162px;font-size:38px}
    .info__team-image{left: 110px;
    top: 55px;
    width: 594px;
    box-sizing: border-box;
    height: 271px;
    font-size: 38px;
    z-index: 99;}
    .info__grid-image {
    width: 596px;
    height: 920px;}
    .info__grid-title.page__program{left:110px}
    .info__grid-title.page__tickets{box-sizing:border-box;width:378px}
    .team-image-map {width:60%; position:absolute; top:5%; left:20%}
    .info__container-content-grid{width:812px}
    .info__container-content-grid.page__program{width:704px}
    .info__container-content-grid.page__program-item{width:812px;margin-top:-270px}
    .info__container-content-grid.page__blog-item{width:812px;margin-top:-486px}
    .info__container-content-grid.page__tickets{width:704px;padding-bottom:110px}
    .info__container-content-grid.page__info{width:704px}
    .info__container-content{box-sizing:border-box;width:430px;margin-right:110px}
    .info__container-content.page__program{margin-right:56px}
    .info__container-content.page__program-item{box-sizing:border-box;width:646px}
    .info__container-content.page__blog-item{box-sizing:border-box;width:646px;right:56px}
    .info__container-content.page__tickets{margin-right:56px}
    .team-button {font-size: 12px; padding:  7px;border: 2px solid black;}
    .team-button:hover {border: 2px solid #f9f6ef;}
    #team-button-kantoor {position:absolute; left:41%; top:8%;}
    #team-button-club {position:absolute; left:11%; bottom:49%;}
    #team-button-cafe {position:absolute; left:70%; bottom:18%;}
    #team-button-restaurant {position:absolute; left:62%; bottom:49%}
     #team-button-klaslokaal {position:absolute; left:69%; top: 8%}
    #team-button-kunstlokaal {position:absolute; left:2%; bottom:68%}

}
.team-button {

    font-weight: bold;
    cursor: pointer;
    color: black;
    background: #f9f6ef;

}
.team-button:hover {
    color: #f9f6ef;
    background: black;
    text-decoration: underline;

}
.is-active {
    text-decoration: underline;

}

.team__container {
    min-height: 250px !important;
}
.undercase {
    text-transform: capitalize;
    cursor: pointer;
}
@keyframes a{
    0%{background-position:0 0}
    to{background-position:100% 0}
}

.container-login100 {
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #9053c7;
  background: -webkit-linear-gradient(-135deg, #c850c0, #4158d0);
  background: -o-linear-gradient(-135deg, #c850c0, #4158d0);
  background: -moz-linear-gradient(-135deg, #c850c0, #4158d0);
  background: linear-gradient(-135deg, #c850c0, #4158d0);
  overflow:hidden;
}

.wrap-login100 {
  width: 660px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 6rem;
}

/*------------------------------------------------------------------
[  ]*/
.login100-pic {
  width: 316px;
}

.login100-pic img {
  max-width: 100%;
}


/*------------------------------------------------------------------
[  ]*/
.login100-form {
  width: 290px;
}

.login100-form-title {
  font-family: Poppins-Bold;
  font-size: 24px;
  color: #333333;
  line-height: 1.2;
  text-align: center;

  width: 100%;
  display: block;
  padding-bottom: 54px;
}


/*---------------------------------------------*/
.wrap-input100 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 10px;
}

.input100 {
  font-family: Poppins-Medium;
  font-size: 15px;
  line-height: 1.5;
  color: #666666;

  display: block;
  width: 100%;
  background: #e6e6e6;
  height: 50px;
  border-radius: 25px;
  padding: 0 30px 0 68px;
}


/*------------------------------------------------------------------
[ Focus ]*/
.focus-input100 {
  display: block;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: rgba(87,184,70, 0.8);
}

.input100:focus + .focus-input100 {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 70px 25px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 70px 25px;
    opacity: 0;
  }
}

.symbol-input100 {
  font-size: 15px;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 35px;
  pointer-events: none;
  color: #666666;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100 + .symbol-input100 {
  color: #57b846;
  padding-left: 28px;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
}

.login100-form-btn {
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: #57b846;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background: #333333;
}



/*------------------------------------------------------------------
[ Responsive ]*/



@media (max-width: 992px) {
  .wrap-login100 {
    padding: 177px 90px 33px 85px;
  }

  .login100-pic {
    width: 35%;
  }

  .login100-form {
    width: 50%;
  }
}

@media (max-width: 768px) {
    .timeline-container {margin-left: -6%; width: 108%;}
    .lnQoIS {width: 20%;}
    .wrap-login100 {
        padding: 100px 80px 33px 80px;
  }

  .login100-pic {
    display: none;
  }

  .login100-form {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .wrap-login100 {
    padding: 100px 15px 33px 15px;
  }
}


/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: white;
  border: 1px solid #c80000;
  border-radius: 13px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
  pointer-events: none;

  font-family: Poppins-Medium;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 13px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

.message {
    color: red;
    padding: 1rem;

}

.text-center {
    text-align: center;
    padding: 1rem;
}

.timeline-container {
    display:flex;
    justify-content:center;

   }

.timeline-container img {
    width: 48%;
}

.program-container {
    display: flex;
    margin-bottom: 3rem;
}

.program-date {
    flex: 0.2;
}
.program-text {
    flex: 1;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;


  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}




